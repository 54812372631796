
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

interface edit {
  name: string;
  position: string;
  note: string;
  email: any;
  phone: string;
  credentialId: {
    role: string;
  };
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
}

interface show {
  bankCentralId: string;
  bankAreaId: string;
  bankBranchId: string;
  schoolId: string;
}

interface items {
  role: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
  disableButton: boolean;
}

export default defineComponent({
  name: "User",
  components: {
    ErrorMessage,
    Field,
    Form,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    let items = reactive<items>({
      role: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      disableButton: false,
    });

    const editValidator = Yup.object().shape({
      role: Yup.string().required("Role Wajib Diisi"),
      name: Yup.string().required("Nama User Wajib Diisi"),
      phone: Yup.string().required("Nomor Telepon Wajib Diisi"),
      // email: String,
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
    });

    const edit = reactive<edit>({
      name: "",
      position: "",
      note: "",
      email: "",
      phone: "",
      credentialId: {
        role: "",
      },
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
    });

    const show = reactive<show>({
      bankCentralId: "",
      bankAreaId: "",
      bankBranchId: "",
      schoolId: "",
    });

    const saveChanges1 = () => {
      items.disableButton = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      ApiService.putWithData("crmv2/main_user/user/" + route.params.id, edit)
        .then((res) => {
          router.push({ path: "/superadmin/user" });
          toast.success("Berhasil Ubah Data User");
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          submitButton.value?.removeAttribute("data-kt-indicator");
          items.disableButton = false;
        });

      //   submitButton.value?.removeAttribute("data-kt-indicator");
      // }, 2000);
    };

    const getIdArea = () => {
      ApiService.getWithoutSlug("crmv2/main_user/user/" + route.params.id)
        .then((res) => {
          edit.name = res.data.name;
          edit.position = res.data.position;
          show.bankCentralId = res.data.bankCentralName;
          show.bankAreaId = res.data.bankAreaName;
          show.bankBranchId = res.data.bankBranchName;
          show.schoolId = res.data.schoolName;
          edit.note = res.data.note;
          edit.email = res.data.email;
          edit.phone = res.data.phone;
          edit.credentialId.role = res.data.credentialId.role;
          edit.address.province = res.data.address.province;
          edit.address.city = res.data.address.city;
          edit.address.regency = res.data.address.regency;
          edit.address.detail = res.data.address.detail;

          getTempProv();
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };

    // untuk mencari id prov dari nama prov yang ada
    const getTempProv = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;

        let indexProv = items.provinsiOptions.findIndex(
          (x) => x.name === edit.address.province
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getAllProvinces"
        ).then((response) => {
          localStorage.setItem(
            "idProvinsi",
            JSON.stringify(response.data[indexProv].id)
          );

          getTempKab();
        });
      });
    };

    // untuk mendapatkan kabupaten dari get id area
    const getTempKab = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getRegencies/" +
          JSON.parse(localStorage.getItem("idProvinsi")!)
      ).then((response) => {
        items.kabupatenOptions = response.data;

        let indexKab = items.kabupatenOptions.findIndex(
          (x) => x.name === edit.address.city
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" +
            JSON.parse(localStorage.getItem("idProvinsi")!)
        ).then((response) => {
          localStorage.setItem(
            "idKab",
            JSON.stringify(response.data[indexKab].id)
          );

          getTempKec();
        });
      });
    };

    // untuk mendapatkan kecamatan dari get id area
    const getTempKec = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getDistricts/" +
          JSON.parse(localStorage.getItem("idKab")!)
      ).then((response) => {
        items.kecamatanOptions = response.data;
      });
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === edit.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === edit.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kecamatanOptions = response.data;
        });
      }
    };

    const getRole = () => {
      ApiService.getWithoutSlug("crmv2/main_user/user/combo_role").then(
        (response) => {
          items.role = response.data;
        }
      );
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      setCurrentPageBreadcrumbs("User", ["Data"]);
      getRole();
      getIdArea();
      getProvinsi();
    });

    return {
      submitButton,
      saveChanges1,
      edit,
      show,
      editValidator,
      items,
      getKabupaten,
      getKecamatan,
      getIdArea,
      getTempProv,
      getTempKab,
      getTempKec,
    };
  },
});
